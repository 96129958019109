import React from 'react';

import Block from './Block';
import SupportContact from '../../partials/SupportContact';

export default function Hebergements() {
  return (
    <>
      <div className="content large">
        <div className="fullwidth">
          <p>Les hôtels ci-dessous, proches d'Exponantes vous proposent des avantages
             tarifaires sur<br/>
           présentation du code&nbsp;: <b>BTLec Ouest 2025</b></p>
        </div>
        <div className="col">
          <Block
            title = "Sure Hotel by Best Western Nantes Beaujoire"
          >
            <p>À 2,6 km du parc des expositions de la Beaujoire.<br/>
            27 rue du Chemin Rouge, 44300 Nantes</p>
            <p><b>105€</b> au lieu de 132€ (-20%)</p>
            <p className="web">Site web : <a href='https://www.nantesbeaujoire.com/' target="_blank">https://www.nantesbeaujoire.com/</a></p>
            <p>Google Maps : <a href='https://maps.app.goo.gl/184AeVaSgQfq7pK1A' target="_blank">https://maps.app.goo.gl/184AeVaSgQfq7pK1A</a></p>
            <p className="tel">Téléphone: <a href="tel:02 14 00 07 89">02 14 00 07 89</a></p>
          </Block>
          <Block
            title = "Brit Hôtel 4*"
          >
            <p>1km du parc des Expositions de la Beaujoire<br/>
            45 Boulevard des Batignolles, 44300 Nantes</p>
            <p><b>170€ B&B</b> au lieu de 197,50€ (soit -14%)</p>
            <p className="web">Site web : <a href='https://hotel-nantes-beaujoire.brithotel.fr/' target="_blank">https://hotel-nantes-beaujoire.brithotel.fr/</a></p>
            <p>Google Maps : <a href='https://maps.app.goo.gl/iiV8TQPhprKjsw7J8' target="_blank">https://maps.app.goo.gl/iiV8TQPhprKjsw7J8</a></p>
            <p className="tel">Téléphone: <a href="tel:02 40 50 07 07">02 40 50 07 07</a></p>
          </Block>
        </div>
        <div className="col">
          <Block
            title = "Ibis Beaujoire"
          >
            <p>À 1,7 km du parc des expositions de la Beaujoire.<br/>
            5 rue du Moulin de la halvèque, 44300 Nantes</p>
            <p>
              <b>149€</b> au lieu de 163€ (-8,5%)
            </p>
            <p className="web">Site web : <a href='https://www.hotel-ibis-labeaujoire.com/' target="_blank">https://www.hotel-ibis-labeaujoire.com/</a></p>
            <p>Google Maps : <a href='https://maps.app.goo.gl/2jt2oAVpsQkGFQtp9' target="_blank">https://maps.app.goo.gl/2jt2oAVpsQkGFQtp9</a></p>
            <p className="tel">Téléphone: <a href="tel:02 40 93 22 22">02 40 93 22 22</a></p>
          </Block>
          <Block
            title = "Comfort Aparthotel Nantes La Beaujoire"
          >
            <p>
              <b>-10%</b> sur le tarif affiché flexible sur toutes les catégories d'appartements
            </p>
            <p className="web">Site web : <a href='https://www.choicehotels.com/fr-fr/france/nantes/comfort-suites-hotels/fr555' target="_blank">https://www.choicehotels.com/fr-fr/france/nantes/comfort-suites-hotels/fr555</a></p>
            <p>Google Maps : <a href='https://maps.app.goo.gl/yEW4bQfxPEGbS92RA' target="_blank">https://maps.app.goo.gl/yEW4bQfxPEGbS92RA</a></p>
          </Block>
        </div>
        <div className="col">
          <Block
            title = "L'hôtel Zenitude la Beaujoire"
          >
            <p>À 2,6 km du parc des expositions de la Beaujoire.<br/>
            2 rue des Citrines 44300 Nantes</p>
            <p><b>-15%</b> sur le tarif affiché flexibles sur toutes les catégories d’appartements</p>
            <p className="web">Site web : <a href='https://www.zenitude-hotel-residences.com/fr_FR/residence/nantes/155' target="_blank">https://www.zenitude-hotel-residences.com/fr_FR/residence/nantes/155</a></p>
            <p>Google Maps : <a href='https://maps.app.goo.gl/CdWbVR68oYXPKf2o7' target="_blank">https://maps.app.goo.gl/CdWbVR68oYXPKf2o7</a></p>
            <p className="tel">Téléphone: <a href="tel:02 28 08 80 00">02 28 08 80 00</a></p>
          </Block>
        </div>
      </div>
      <div className="support content large">
        <SupportContact breakingPoint={900}/>
      </div>
    </>
  );
}
