import React, { useState } from 'react';
import DownloadFile from '../../partials/DownloadFile';
import { getAssemblyTicket } from '../../../actions/montage';
import { API_URL } from '../../../actions/index';

export default function Fichiers({ showroomId }) {
  const [isLoading, setIsLoading] = useState(false);

  function downloadAll() {
    const links = document.getElementById('download').querySelectorAll('a');
    const arrLinks = Array.from(links);

    arrLinks.forEach((a) => {
      a.click();
    });
  }

  function downloadAssemblyTicket() {
    setIsLoading(true);
    getAssemblyTicket(showroomId).then(() => {
      setIsLoading(false);
    });
  }

  return (
    <div className="fichiers">
      <div className="content">
        <h3>Adresse</h3>
        <p>
          Exponantes<br/>
          Grand Palais<br/>
          Salon Multimédia et Electro<br/>
          Parc des Expositions<br/>
          Route de Saint Joseph de Porterie<br/>
          44300 NANTES<br/>
          Accès véhicules&nbsp;: Porte 2
        </p>
        <button onClick={downloadAssemblyTicket}>Télécharger l’étiquette de livraison {isLoading && <span className="loader"></span>}</button>
        <h3>Fichiers utiles</h3>
        <button onClick={downloadAll}>Tout télécharger</button>
        <span id="download">
          <DownloadFile desc={"Consignes d'installation 2025"} url={`${API_URL}/static/CONSIGNES_INSTALLATION_SALON_DEMONTAGE.pdf`}/>
          <DownloadFile desc={'Guide des expositions 2025'} url={`${API_URL}/static/Guide_Exposants_PDV.pdf`}/>
          <DownloadFile desc={"Macaron d'accès véhicule léger"} url={`${API_URL}/static/macaron_acces_vl.pdf`}/>
          <DownloadFile desc={"Macaron d'accès camion"} url={`${API_URL}/static/macaron_acces_camion.pdf`}/>
          <DownloadFile desc={'Cheminement véhicules et piétons'} url={`${API_URL}/static/cheminement_vehicules-pietons.pdf`}/>
          <DownloadFile desc={'Plan du parc des expositions'} url={`${API_URL}/static/PLAN_EXPONANTES.pdf`}/>
          <DownloadFile desc={'Consignes de tri des déchets'} url={`${API_URL}/static/consignes_tri_dechets.pdf`}/>
        </span>
      </div>
    </div>
  );
}
