import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import { NavLink } from 'react-router-dom';
import Select from 'react-select';
import InputField from '../partials/form_fields/InputField';
import selectStyle from '../partials/form_fields/selectStyle';

import LogoLeclerc from '../../assets/images/logo-leclerc.svg';
import LoginImg from '../../assets/images/login-img.png';
import IconLock from '../../assets/images/icons/lock_icon.svg';
import LogoParcExpo from '../../assets/images/logo_parc_expo.svg';

import { useGlobalContext } from '../GlobalContext';
import {
  signIn,
  resetError,
  // resetAuth
} from '../../actions/auth';
// import { resetCompany } from '../../actions/company';

export default function Login(props) {
  const [context, dispatch] = useGlobalContext();
  const [loginData, setLoginData] = useState({});
  const [disabled, setDisabled] = useState(true);
  const [optionsUsers, setOptionsUsers] = useState();
  const [selectedUser, setSelectedUser] = useState();

  const { authReducer, exhibitionReducer } = context;
  const { exhibition } = exhibitionReducer;

  // ACTIONS
  const _signIn = (data) => signIn(dispatch, data);
  const _resetError = () => resetError(dispatch);
  // const _resetAuth = () => resetAuth(dispatch)
  // const _resetCompany = () => resetCompany(dispatch)

  useEffect(() => {
    const id = localStorage.getItem('id');
    const token = localStorage.getItem('token');
    if (
      id && token
      && authReducer.user
      && authReducer.user._id
    ) {
      props.history.push('/redirect');
    }
  }, [authReducer.user]);

  useEffect(() => {
    if (!authReducer.contacts) return;
    const options = [];
    let company;

    authReducer.contacts.forEach((contact) => {
      if (contact.type !== 'company') {
        // options.push({ label: `${contact.profile.firstName} ${contact.profile.lastName}`, value: contact._id });
      } else if (exhibition.loginActive && contact.type === "company"){
        company ={ label : "Inscription magasin" , value : contact._id}
      }
    });

    if (company) options.unshift(company);

    setOptionsUsers(options);
  }, [authReducer.contacts]);

  // HANDLE DISABLED SUBMIT BUTTON
  useEffect(() => {
    if (loginData.email !== '' && loginData.password !== '') {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  }, [loginData.email, loginData.password]);

  // IF ERROR REMOVE MESSAGE AFTER 2500
  useEffect(() => {
    if (authReducer.error) {
      const timer = setTimeout(() => {
        clearInterval(timer);
        _resetError();
      }, 2500);
      return () => {
        clearInterval(timer);
        _resetError();
      };
    }
  }, [authReducer.error]);

  function handleChange(val, field) {
    if (field === 'email-user') {
      setSelectedUser(val);
    }

    const obj = { ...loginData };
    obj[field] = val;
    setLoginData(obj);
  }

  function handlePressEnter(e) {
    if (e.key !== 'Enter') return;
    if (!disabled) {
      const data = _.cloneDeep(loginData);
      if (data.contact) {
        data.contact = data.contact.value;
      }
      _signIn(data);
    }
  }

  function login() {
    const data = _.cloneDeep(loginData);
    if (data.contact) {
      data.contact = data.contact.value;
    }
    _signIn(data);
  }

  return (
    <div className="page login" >
      <div className="content">
        <div className="left">
          <img src={LoginImg} alt="Salon multimedia et electro" />
        </div>
        <div className="form-login" onKeyPress={handlePressEnter}>
          <NavLink to="/">
            <img src={LogoLeclerc} alt="logo leclerc" />
            <h1>BTLec Ouest</h1>
            <h2>Salon Multimédia et Electro <br/>14/15&nbsp;mai 2025</h2>
          </NavLink>
          <div className="address">
            <img src={LogoParcExpo} alt="parc expo nantes" />
            <p>
              EXPO NANTES - Grand&nbsp;Palais<br/>
              Route de Saint-Joseph de Porterie<br/>
              44300 NANTES
            </p>
          </div>
          <div className="field-icon">
            <InputField
              value={''}
              placeholder={'Saissisez votre code ACDLEC'}
              type={'string'}
              handleChange={(val) => handleChange(val, 'email')}
            />
          </div>
          <div className="field-icon">
            <InputField
              value={''}
              placeholder={'Saissisez votre mot de passe'}
              type={'password'}
              required={true}
              handleChange={(val) => handleChange(val, 'password')}
            />
            <img src={IconLock} alt="mot de passe" />
          </div>
          { optionsUsers
            ? <div className="field-icon">
              <Select
                onChange={(val) => handleChange(val, 'contact')}
                options={optionsUsers}
                value={selectedUser}
                isSearchable={false}
                styles={selectStyle}
              />
            </div> : null
          }

          <div className="centered">
            <button className={disabled ? 'disabled' : ''} onClick={login}>Connexion</button>
          </div>

          { authReducer.error
            ? <div className="centered">
              <p className="warn">Erreur d'authentification, veuillez vérifier vos identifiants.</p>
            </div> : null
          }
        </div>
      </div>
    </div>
  );
}
